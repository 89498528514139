import React, { useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
const email = process.env.REACT_APP_SUPABASE_EMAIL;

export default function PassCheck(props) {
  const { supabase, onSuccess, onFailed } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");

  const checkPassword = async (inputPassword) => {
    setLoading(true);
    console.log("Checking password...", inputPassword, email);
    const { data, error } = await supabase.login(email, inputPassword);
    if (error) {
      console.log("Error", error);
      setPassword("");
      if (onFailed) {
        onFailed();
      }
      setLoading(false);
      return;
    }
    if (data) {
      console.log("Success", data);
      console.log("props", props);
      console.log("onSuccess", onSuccess);
      if (onSuccess) {
        const result = await onSuccess();
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {/* <form onSubmit={(e) => e.preventDefault()}> */}
      <Grid container spacing={0.5}>
        <Grid item xs={8}>
          <TextField // Add a text field for the password
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            autoFocus
            fullWidth
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                checkPassword(password);
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={1} // Add a button to submit the password
        >
          <LoadingButton
            fullWidth
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={() => checkPassword(password)}
            style={{ marginLeft: "10px", height: "100%" }}
          >
            <SendIcon />
          </LoadingButton>
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
}
