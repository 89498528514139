import React, { useRef, useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import PhotoCardUpload from "../../components/PhotoCardUpload";
import Utils from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import TakePhoto from "./TakePhoto";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { Fab } from "@mui/material";
import YesNo from "../../components/yesNo";
import SendIcon from "@mui/icons-material/Send";
import { ForkLeft } from "@mui/icons-material";
const PhotoChoose = (props) => {
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [photo, setPhoto] = useState({});
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const utils = new Utils();

  useEffect(() => {
    if (photo.url) {
      console.log("Photo URL:", photo.url);
      // navigate("/photos/edit", { state: { photo: photo } });
    }
  }, [photo]);

  const fileInputRef = useRef(null);

  const handleDeltePhoto = (id) => {
    console.log("Delete the photo with id:", id);
    props.setModal(
      <YesNo
        onYes={() => {
          props.setModal(null);
          setPhotos((prevPhotos) =>
            prevPhotos.filter((photo) => photo.id !== id)
          );
        }}
        onNo={() => {
          props.setModal(null);
        }}
        query="Delete this photo?"
      />
    );
  };

  const handlePublicUploadGroup = async () => {
    setLoading(true);
    photos.forEach(async (photo) => {
      handlePublicUpload(photo);
    });
    setLoading(false);
  };

  const handlePublicUpload = async (photo) => {
    const blob = base64ToBlob(photo.base64data, "image/png");
    setLoading(true);
    const uuid = utils.generateUUID();
    const filename = `${uuid}.png`;
    const { data, error } = await supabase.uploadPublicPics(filename, blob);
    if (error) {
      if (error.message === "Request failed with status code 413") {
        props.setToast("File size is too large", "error");
      }

      // Handle error
      console.log("Error uploading photo:", error);
    } else {
      // Handle success
      console.log("Photo uploaded successfully:", data);
      navigate("/photos");
    }
    setLoading(false);
  };

  const base64ToBlob = (base64, mimeType) => {
    console.log("base64", base64);

    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  const handlePrivateUploadGroup = async (photos) => {
    setLoading(true);
    photos.forEach(async (photo) => {
      handlePrivateUpload(photo);
    });
    setLoading(false);
  };

  const handlePrivateUpload = async (photo) => {
    console.log("Private upload");
    console.log("Photo:", photo);
    const blob = base64ToBlob(photo.base64data, "image/png");
    setLoading(true);
    const uuid = utils.generateUUID();
    const filename = `${uuid}.png`;
    const { data, error } = await supabase.uploadPrivatePics(filename, blob);
    if (error) {
      // Handle error
      console.log("Error uploading photo:", error);
      props.setToast("Error uploading photo", "error");
    } else {
      // Handle success
      console.log("Photo uploaded successfully:", data);
      navigate("/photos");
    }
    setLoading(false);
  };

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;
        console.log("Base64 data:", base64data);
        // Upload the base64data to your server or storage service
        // Example: Upload to Supabase storage
        // base64data is a string, so we need to convert it to a blob
        const blob = await fetch(base64data).then((res) => res.blob());
        console.log("Blob data:", blob);
        const uuid = utils.generateUUID() + ".png";
        console.log("UUID:", uuid);

        const downloadUrl = URL.createObjectURL(blob);

        // check if there is content in the image
        if (!downloadUrl) {
          console.error("No content in the image");
          return;
        }

        // Update the photos list with the new photo
        setPhotos((prevPhotos) => [
          ...prevPhotos,
          {
            base64data: base64data,
            id: photos.length + downloadUrl,
            title: downloadUrl,
            url: downloadUrl,
          },
        ]);
        // setPhoto({
        //   base64data: base64data,
        //   id: downloadUrl,
        //   title: downloadUrl,
        //   url: downloadUrl,
        // });
      };
      reader.readAsDataURL(file);
    }
    setLoading(false);
  };

  return (
    <div
      // minimize the padding on the side
      sx={{ padding: 0 }}
    >
      <Container maxWidth="sm" style={{ marginTop: "20px", padding: "20px" }}>
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          // add spacing at the bottom
          paddingBottom={2}
          style={{ fontFamily: "Adelia, sans-serif" }}
        >
          Upload
        </Typography>
        <Grid container spacing={1} sx={{ marginBottom: "20px" }}>
          {photos.map((photo) => (
            <Grid
              size={{
                xs: 4,
                md: 3,
                lg: 2,
              }}
              item
            >
              <PhotoCardUpload
                // width={width}
                photo={photo}
                deletePhoto={(id) => {
                  handleDeltePhoto(id);
                }}
              />
            </Grid>
          ))}
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            height="80px"
            width="90%"
          >
            <input
              type="file"
              accept="image/*,video/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Fab
              color="primary"
              aria-label="add"
              onClick={() => fileInputRef.current.click()}
              style={{
                position: "fixed",
                bottom: "70px",
                right: "20px",
                animation: "popIn 0.2s ease-in-out",
                width: "50px",
                height: "50px",
                minHeight: "50px",
              }}
            >
              <AddIcon />{" "}
            </Fab>
          </Box>
        </Grid>
      </Container>
      <Grid
        container
        spacing={0.5}
        style={{
          // position: "absolute",
          // bottom: "10",
          position: "fixed",
          left: "10px",
          bottom: "70px",
          width: "100%",
        }}
      >
        <Grid item size={8}>
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handlePublicUploadGroup}
            fullWidth
            disabled={!photo}
          >
            Share
          </Button>
        </Grid>
        <Grid item size={8}>
          <Button
            variant="contained"
            disabled={!photo}
            endIcon={<SendIcon />}
            fullWidth
            onClick={handlePrivateUploadGroup}
          >
            Share privately
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhotoChoose;
