import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { Container } from "@mui/material";
const TakePhoto = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);
  const [photo, setPhoto] = useState(null);

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px", padding: "20px" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        // add spacing at the bottom
        paddingBottom={2}
        style={{ fontFamily: "Adelia, sans-serif" }}
      >
        Gallery
      </Typography>
      {error && <p>{error}</p>}

      {photo && (
        <div>
          <h2>Your Photo</h2>
          <img src={photo} alt="Captured" />
        </div>
      )}
    </Container>
  );
};

export default TakePhoto;
