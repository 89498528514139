import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Navbar from "./components/Navbar";
import RsvpNew from "./pages/rsvp/RsvpNew";
import Rsvp from "./pages/rsvp/Rsvp";
import RsvpCheck from "./pages/rsvp/RsvpCheck";
import Blog from "./pages/blog/Blog";
import Photos from "./pages/photos/Photos";
import Schedule from "./pages/schedule/Schedule";
import { Route, Routes } from "react-router-dom";
import Info from "./pages/info/Info";
import SupabaseClient from "./models/SupabaseClient";
import RsvpEdit from "./pages/rsvp/RsvpEdit";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress } from "@mui/material";
import Modal from "@mui/material/Modal";
import BottomNav from "./components/BottomNav";
import HomeIcon from "@mui/icons-material/Home";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import InfoIcon from "@mui/icons-material/Info";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LockIcon from "@mui/icons-material/Lock";
import Foeo from "./pages/foeo/Foeo";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PassCheck from "./components/PassCheck";

import NotFound from "./pages/notFound/NotFound";
import Story from "./pages/story/Story";
import PhotoCapture from "./pages/photos/PhotoCapture";
import PhotoEdit from "./pages/photos/PhotoEdit";
import PhotoApp from "./pages/photos/PhotoApp";
import PhotoChoose from "./pages/photos/PhotoChoose";
const supabase = new SupabaseClient();
const isMobile = window.innerWidth < 768;
const initPages = [
  { title: "Home", link: "/home", icon: <HomeIcon /> },
  { title: "RSVP", link: "/check-rsvp", icon: <EditCalendarIcon /> },
  // { title: "Blog", link: "/blog" },
  { title: "Photos", link: "/photos", icon: <PhotoCameraIcon />, Admin: true },
  { title: "Schedule", link: "/schedule", icon: <ScheduleIcon />, Admin: true },
  { title: "info", link: "/info", icon: <InfoIcon /> },
  { title: "Story", link: "/story", icon: <InfoIcon />, Admin: true },

  {
    title: "Admin",
    link: "/foeo",
    icon: <AdminPanelSettingsIcon />,
    Admin: true,
  },
];

function App() {
  const toastId = React.useRef(null);
  const [pages, setPages] = useState(initPages.filter((page) => !page.Admin));
  const [unlocked, setUnlocked] = useState(true);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    verifyUSer();
  }, []);

  const verifyUSer = async () => {
    // setToast("Verifying user", "info");
    // const user = localStorage.getItem("user");
    setUnlocked(true);
    setLoading(false);
    return true;

    console.log("Verifying user");
    const { data, error, expired } = await supabase.getUser();
    if (error || expired) {
      setToast("Error verifying user", "error");
      setPages(initPages.filter((page) => page.title === "Home"));

      console.log("No user found");
      localStorage.removeItem("user");

      setUnlocked(false);
      setLoading(false);
      setModal(
        <PassCheck
          supabase={supabase}
          onSuccess={() => {
            verifyUSer();
            setModal(null);
          }}
          onFailed={() => {
            setToast("Incorrect password", "error");
          }}
        />
      );
      return false;
    }

    if (data) {
      console.log("User found and not expired");
      setUnlocked(true);
      setLoading(false);
      let allPages = initPages.filter((page) => !page.Admin);

      if (
        data.id === "4aa41ed9-c5af-4b8f-8d10-c1b890626a9d" ||
        data.id === "a9dc859e-cc3e-48aa-984c-0f327409b767"
      ) {
        // check if admin page is already in the pages
        // change icon if for Admin only pages
        allPages = initPages;
        allPages.forEach((page) => {
          if (page.Admin && page.title !== "Admin") {
            page.icon = <LockIcon />;
          }
        });
      }
      setPages(allPages);

      return true;
    }

    setPages(initPages.filter((page) => page.title === "Home"));

    console.log("No user found");
    localStorage.removeItem("user");

    setUnlocked(false);
    setLoading(false);
    setModal(
      <PassCheck
        supabase={supabase}
        onSuccess={() => {
          verifyUSer();
          setModal(null);
        }}
        onFailed={() => {
          setToast("Incorrect password", "error");
        }}
      />
    );

    return false;
    // redirect to home
    // if (window.location.pathname !== "/") {
    //   window.location.href = "/";
    // }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      verifyUSer();
      console.log("Route changed");
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  const setToast = (message, type, time = 1500, id = null) => {
    if (id) {
      toast.dismiss(id);
    }
    toastId.current = toast(message, {
      type: type,
      position: isMobile ? "top-right" : "bottom-right",
      autoClose: time,
      pauseOnFocusLoss: false,
      toastId: id,
    });
    return toastId.current;
  };

  if (loading) {
    return (
      <div className="App">
        <CircularProgress />
      </div>
    );
  }

  function Navigation() {
    if (isMobile) {
      return <BottomNav pages={pages} />;
    }
    return <Navbar pages={pages} />;
  }

  return (
    <div className="App">
      <ToastContainer />
      <Modal
        open={!!modal}
        onClose={() => {
          if (unlocked) {
            setModal(null);
          }
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            minWidth: "200px",

            padding: "20px",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          {modal}
        </div>
      </Modal>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <>
              {isMobile & unlocked ? <BottomNav pages={pages} /> : null}
              <Home
                supabase={supabase}
                unlocked={unlocked}
                setUnlocked={setUnlocked}
                setToast={setToast}
                setModal={setModal}
                verifyUSer={verifyUSer}
                isMobile={isMobile}
              />
            </>
          }
        />
        <Route
          path="/home"
          exact
          element={
            <>
              {isMobile & unlocked ? <BottomNav pages={pages} /> : null}
              <Home
                supabase={supabase}
                unlocked={unlocked}
                setUnlocked={setUnlocked}
                setToast={setToast}
                setModal={setModal}
                verifyUSer={verifyUSer}
                isMobile={isMobile}
              />
            </>
          }
        />

        {/* <Route
              path="/check-rsvp"
              element={
                <>
                  <PageWrapper>
                    <Navbar />
                    <Rsvp />
                  </PageWrapper>
                </>
              }
            /> */}
        <Route
          path="/create-rsvp"
          element={
            <>
              <PageWrapper>
                <Navigation />
                <RsvpNew />
              </PageWrapper>
            </>
          }
        />
        <Route
          path="/check-rsvp"
          element={
            <>
              <PageWrapper>
                <Navigation />
                <RsvpCheck supabase={supabase} setToast={setToast} />
              </PageWrapper>
            </>
          }
        />
        <Route
          path="/story"
          element={
            <>
              <PageWrapper>
                <Navigation />
                <Story supabase={supabase} setToast={setToast} />
              </PageWrapper>
            </>
          }
        />
        <Route
          path="/edit-rsvp"
          element={
            <>
              <PageWrapper>
                <Navigation />
                <RsvpEdit
                  supabase={supabase}
                  setToast={setToast}
                  setModal={setModal}
                  verifyUSer={verifyUSer}
                />
              </PageWrapper>
            </>
          }
        />
        {unlocked ? (
          <>
            <Route
              path="/blog"
              element={
                <>
                  <PageWrapper>
                    <Navigation />
                    <Blog />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/photos"
              exact
              element={
                <>
                  <PageWrapper>
                    <Navigation />
                    <Photos supabase={supabase} setModal={setModal} />
                  </PageWrapper>
                </>
              }
            ></Route>
            {/* <Route path="/photos/capture" element={<PhotoCapture />} /> */}
            <Route
              path="/photos/edit"
              element={
                <PhotoEdit
                  photo
                  supabase={supabase}
                  setModal={setModal}
                  setToast={setToast}
                />
              }
            />
            <Route
              path="/photos/app"
              element={
                <PhotoApp
                  photo
                  supabase={supabase}
                  setModal={setModal}
                  setToast={setToast}
                />
              }
            />
            <Route
              path="/photos/choose"
              element={
                <PageWrapper>
                  <Navigation />
                  <PhotoChoose
                    photo
                    supabase={supabase}
                    setModal={setModal}
                    setToast={setToast}
                  />
                </PageWrapper>
              }
            />
            <Route
              path="/schedule"
              element={
                <>
                  <PageWrapper>
                    <Navigation />
                    <Schedule />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/info"
              element={
                <>
                  <PageWrapper>
                    <Navigation />
                    <Info />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="/foeo"
              element={
                <>
                  <PageWrapper>
                    <Navigation />
                    <Foeo
                      supabase={supabase}
                      setModal={setModal}
                      setToast={setToast}
                      verifyUser={verifyUSer}
                    />
                  </PageWrapper>
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <Navigation />
                  <NotFound />
                </>
              }
            />
          </>
        ) : (
          <Route
            path="*"
            element={
              <>
                <Navigation />
                <NotFound />
              </>
            }
          />
        )}
      </Routes>
    </div>
  );
}

const PageWrapper = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <>
      <div
        style={{
          background: isHomePage ? "none" : "url(/img/250116homepage.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          position: "fixed",
          top: 0,
          left: 0,

          height: "100vh",
          width: "100vw",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          filter: "blur(5px)",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          backgroundSize: "cover",
          backgroundPosition: "center",

          position: "fixed",
          top: 0,
          left: 0,

          height: "100vh",
          width: "100vw",

          // blur the background
          // filter: "blur(10px)",
        }}
      ></div>
      <div
        style={{
          position: "relative",
          height: "100vh",
          width: "100vw",
          zIndex: 1,
        }}
      >
        {children}
      </div>
    </>
  );
};
export default App;
