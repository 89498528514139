import React, { useState, useEffect, useRef } from "react";
import { Card, CircularProgress, Fab } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PhotoModal from "../../components/photoModal";
import PhotoCardMedia from "../../components/PhotoCardMedia";
import { Container } from "@mui/material";
export default function PhotosGrid({
  photos,
  imageLoading,
  handleImageLoad,
  handleImageLoadStart,
  lastPhotoElementRef,
  setModal,
  loading,
  handleNavigate,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [width, setWidth] = useState(250);
  const [fullScreen, setFullScreen] = useState(false);
  const imageRefs = useRef([]);
  const navigateToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    console.log("Navigate to the next image");
  };

  useEffect(() => {
    if (imageRefs.current[currentIndex]) {
      imageRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    console.log("fullScreen", fullScreen);
  }, [width, fullScreen]);

  return (
    <div
      // make sure there is no horizontal scrolling
      style={{ overflowX: "hidden" }}
    >
      <Grid
        container
        spacing={1}
        sx={{ justifyContent: "center", marginBottom: "20px" }}
      >
        {photos.map((photo, index) => {
          if (photos.length === index + 1) {
            return (
              <Grid
                size={{
                  xs: fullScreen ? 12 : 4,
                  md: fullScreen ? 6 : 3,
                  lg: fullScreen ? 4 : 2,
                }}
                item
                ref={lastPhotoElementRef}
              >
                <div
                  key={photo.id}
                  ref={(el) => (imageRefs.current[index] = el)}
                  onClick={() => {
                    console.log("clicked");
                    setFullScreen(!fullScreen);
                    setCurrentIndex(index);
                  }}
                >
                  <PhotoCardMedia
                    // width={width}
                    photo={photo}
                    imageLoading={imageLoading}
                    handleImageLoad={handleImageLoad}
                    handleImageLoadStart={handleImageLoadStart}
                    // navigateToNextImage={navigateToNextImage} // Pass the navigation function
                  />
                </div>
              </Grid>
            );
          } else {
            return (
              <Grid
                size={{
                  xs: fullScreen ? 12 : 4,
                  md: fullScreen ? 6 : 3,
                  lg: fullScreen ? 4 : 2,
                }}
                item
              >
                <div
                  key={photo.id}
                  ref={(el) => (imageRefs.current[index] = el)}
                  onClick={() =>
                    // setModal(
                    //   <PhotoModal url={photo.url} toNext={navigateToNextImage} />
                    // )
                    {
                      console.log("clicked");
                      console.log("fullScreen", fullScreen);
                      setFullScreen(!fullScreen);
                      setCurrentIndex(index);
                    }
                  }
                >
                  {imageLoading[photo.id] && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "250px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  <PhotoCardMedia
                    // width={width}
                    photo={photo}
                    imageLoading={imageLoading}
                    handleImageLoad={handleImageLoad}
                    handleImageLoadStart={handleImageLoadStart}
                    navigateToNextImage={navigateToNextImage} // Pass the navigation function
                  />
                </div>
              </Grid>
            );
          }
        })}
      </Grid>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
