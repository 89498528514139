import React, { useEffect, useState } from "react";
import { Container, TextField, Typography } from "@mui/material";
import FoeoTable from "./FoeoTable";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import Login from "../../components/Login";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from "@mui/icons-material/Cached";

const Foeo = (props) => {
  const supabase = props.supabase;
  const [data, setData] = useState(null);
  const [attending, setAttending] = useState(0);
  const [attendingAdult, setAttendingAdult] = useState(0);
  const [attendingChild, setAttendingChild] = useState(0);
  const [notAttending, setNotAttending] = useState(0);
  const [notResponded, setNotResponded] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [attendingPercentage, setAttendingPercentage] = useState(0);
  const [notAttendingPercentage, setNotAttendingPercentage] = useState(0);
  const [notRespondedPercentage, setNotRespondedPercentage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGuests();
  }, [supabase]);

  const fetchGuests = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase.getGuests();
      if (error) {
        console.error("Error fetching guests", error);
        setLoading(false);
        return;
      }
      console.log("Data from table:", data);
      setData(data);
      setFilteredData(data);
    } catch (error) {
      console.error("Error fetching guests", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // count guests that are attending
    if (!data) return;
    const attending = data.filter((guest) => guest.attending === true);
    const notAttending = data.filter((guest) => guest.attending === false);
    const attendingAdult = attending.filter((guest) => guest.child === false);
    const attendingChild = attending.filter((guest) => guest.child === true);
    const notResponded = data.filter((guest) => guest.attending === null);
    console.log("Attending:", attending);
    setAttendingAdult(attendingAdult.length);
    setAttendingChild(attendingChild.length);
    setAttending(attending.length);
    setNotAttending(notAttending.length);
    setNotResponded(notResponded.length);
    const total = attending.length + notAttending.length + notResponded.length;
    setAttendingPercentage((attending.length / total) * 100);
    setNotAttendingPercentage((notAttending.length / total) * 100);
    setNotRespondedPercentage((notResponded.length / total) * 100);
  }, [data]);

  const filterData = (search) => {
    const filteredData = data.filter((guest) => {
      return Object.values(guest).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      );
    });
    setFilteredData(filteredData);
  };

  const showLogin = () => {
    props.setModal(
      <Login
        supabase={supabase}
        onSuccess={() => {
          fetchGuests();
          props.verifyUser();
          props.setModal(null);
        }}
        onFailed={() => {
          props.setToast("Login failed", "error");
        }}
      />
    );
  };

  const logout = async () => {
    const { error } = await supabase.logout();
    if (error) {
      console.error("Error logging out", error);
      return;
    }

    props.verifyUser();
  };

  return (
    <Container
      // add padding
      style={{ paddingTop: "20px" }}
    >
      <Typography variant="h4" gutterBottom>
        Foeo Table{" "}
        <IconButton variant="contained" onClick={showLogin}>
          <PersonIcon />
        </IconButton>
        <IconButton variant="contained" onClick={logout}>
          <ExitToAppIcon />
        </IconButton>
      </Typography>

      <Box
        sx={{
          width: "100%",
          marginBottom: "20px",
          position: "relative",
          height: "20px",
          backgroundColor: "#e0e0e0",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: `${attendingPercentage}%`,
            height: "100%",
            backgroundColor: "lightgreen",
            position: "absolute",
            left: 0,
          }}
        />
        <Box
          sx={{
            width: `${notAttendingPercentage}%`,
            height: "100%",
            backgroundColor: "lightcoral",
            position: "absolute",
            left: `${attendingPercentage}%`,
          }}
        />
        <Box
          sx={{
            width: `${notRespondedPercentage}%`,
            height: "100%",
            backgroundColor: "#lightgrey",
            position: "absolute",
            left: `${attendingPercentage + notAttendingPercentage}%`,
          }}
        />
      </Box>
      <Typography variant="body1">
        Attending: {attending} ({attendingAdult}/{attendingChild}), Not
        Attending: {notAttending}, Not Responded: {notResponded}, Total:{" "}
        {attending + notAttending + notResponded}
        {/* add iconbutton  */}
        <IconButton variant="contained" onClick={fetchGuests}>
          <CachedIcon />
        </IconButton>
      </Typography>
      <br />

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        style={{ marginBottom: "20px" }}
        onChange={(e) => {
          filterData(e.target.value);
        }}
      />
      <br />
      {data === null ? (
        <Typography>You have no rights</Typography>
      ) : data.length === 0 ? (
        <Typography>No data</Typography>
      ) : (
        <FoeoTable data={filteredData} loading={loading} />
      )}
    </Container>
  );
};

export default Foeo;
