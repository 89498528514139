import React, { useState } from "react";
import { TextField, Button, Container, Typography, Icon } from "@mui/material";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
export default function RsvpEdit(props) {
  const rsvp = JSON.parse(localStorage.getItem("rsvp"));
  const urlParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const [bookingNumber, setBookingNumber] = useState(
    urlParams.get("bookingNumber")
      ? urlParams.get("bookingNumber")
      : rsvp
      ? rsvp.bookingNumber
      : ""
  );
  const [lastName, setLastName] = useState(
    urlParams.get("lastName") & urlParams.get("bookingNumber")
      ? urlParams.get("lastName")
      : rsvp
      ? rsvp.lastName
      : ""
  );
  const firstName = urlParams.get("firstName")
    ? urlParams.get("firstName")
    : rsvp
    ? rsvp.firstName
    : "";
  const [loading, setLoading] = useState(false);

  const supabase = props.supabase;
  const checkRsvp = async () => {
    console.log("Checking RSVP");
    setLoading(true);
    const { data, error } = await supabase.getGuestsByNameAndCode(
      bookingNumber,
      lastName
    );
    if (error) {
      console.error("Error getting guests", error);
      props.setToast(
        "Error getting guests " +
          error.code +
          " - " +
          error.hint +
          " - " +
          error.message +
          " - " +
          error.details,
        "error"
      ); //code details hint message
      setLoading(false);
      return;
    }
    if (data.length === 0) {
      props.setToast("RSVP Not Found", "error");
      setLoading(false);
      return;
    }
    console.log("Data", data);
    // save to local storage
    localStorage.setItem(
      "rsvp",
      JSON.stringify({
        bookingNumber: bookingNumber,
        lastName: lastName,
      })
    );
    // props.setToast("RSVP Found!", "success");

    navigate(
      "/edit-rsvp/?bookingNumber=" + bookingNumber + "&lastName=" + lastName
    );

    console.log(data);
  };

  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: "20px", padding: "20px" }}>
        {/* <IconButton
          edge="start"
          color="inherit"
          aria-label="back"
          onClick={() => navigate(-1)}
          sx={{ position: "absolute", top: "70px", left: "10px", mr: 2 }}
        >
          <ArrowBackIcon />
        </IconButton> */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          // add spacing at the bottom
          paddingBottom={2}
          style={{ fontFamily: "Adelia, sans-serif" }}
        >
          RSVP
        </Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            checkRsvp();
          }}
        >
          <TextField
            label="Rsvp Code"
            variant="outlined"
            margin="normal"
            fullWidth
            id="bookingNumber"
            name="bookingNumber"
            value={bookingNumber}
            required
            onChange={(e) => setBookingNumber(e.target.value)}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            name="lastName"
            value={lastName}
            required
            onChange={(e) => setLastName(e.target.value)}
          />
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            margin="normal"
            fullWidth
            loading={loading}
          >
            Go
          </LoadingButton>
        </form>
      </Container>
    </>
  );
}
