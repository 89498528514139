import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Countdown from "../../components/Countdown.tsx";
import Button from "@mui/material/Button";
import "./Home.css"; // Import the CSS file for animations
import { IconButton, TextField } from "@mui/material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import LockIcon from "@mui/icons-material/Lock";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import PassCheck from "../../components/PassCheck.jsx";
import GetAppIcon from "@mui/icons-material/GetApp";
export default function Home(props) {
  // const weddingDate = "2024-12-31T00:00:00";
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [unlocked, setUnlocked] = useState(true);
  const weddingDate = new Date("2025-07-20T14:00:00");
  const [password, setPassword] = useState("");
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [modal, setModal] = useState(null);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      console.log("beforeinstallprompt event was fired.");
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    console.log("Home props", props);
    setUnlocked(props.unlocked);
    if (props.unlocked) {
      setModal(null);
    }
  }, [props]);

  const navigateTo = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    if (unlocked) {
      setModal(null);
    }
  }, [unlocked]);

  const unlocking = () => {
    props.setModal(
      <PassCheck
        supabase={supabase}
        onSuccess={() => {
          props.setModal(null);
          props.verifyUSer();
        }}
        onFailed={() => {
          props.setToast("Incorrect password", "error");
        }}
      />
    );
  };

  const handleActivateCamera = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (err) {
      console.error("Error accessing the camera: ", err);
    }
  };
  const handleStopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    }
  };

  const handleScreenClick = (e) => {
    const heart = document.createElement("div");
    heart.className = "heart";
    heart.style.left = `${e.clientX}px`;
    heart.style.top = `${e.clientY}px`;
    document.body.appendChild(heart);

    setTimeout(() => {
      heart.remove();
    }, 2000);
  };

  return (
    <div onClick={handleScreenClick} style={{ overflow: "hidden" }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          color: "white",
          backgroundImage: 'url("/img/250116homepage.jpg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          userSelect: "none",
          // add blur
          filter: unlocked ? "" : "blur(20px)",
        }}
      >
        <div
          style={{
            position: "absolute",
            color: "black",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Add text contour
          }}
        >
          <h1
            style={{
              fontFamily: "'Great Vibes', cursive",
              fontSize: "3em",
              lineHeight: "1em",
              textAlign: "left",
              paddingLeft: "70px",
              margin: "0",
              color: "white",
              userSelect: "none",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)", // Add text contour
            }}
          >
            Tiffany
            <br />&
            <br />
            {"   "}Stephane
          </h1>
          <hr style={{ width: "200px", margin: "10px auto" }} />
          <p
            style={{
              margin: "5px 0",
              color: "white",
              userSelect: "none",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)", // Add text contour
            }}
          >
            Join us to celebrate our special day
          </p>
          <hr style={{ width: "200px", margin: "10px auto" }} />
          <p
            style={{
              margin: "5px 0",
              color: "white",
              userSelect: "none",
              textShadow: "2px 2px 4px rgba(0, 0, 0, 1)", // Add text contour
            }}
          >
            20th July 2025
          </p>
          <br />
          <br />
          <Countdown toDate={weddingDate.toISOString()} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {unlocked & !props.isMobile ? (
              <>
                <Button
                  variant="contained"
                  onClick={navigateTo("/check-rsvp")}
                  style={{
                    marginTop: "20px",
                    width: "150px",
                  }}
                  // add icon
                  startIcon={<EventAvailableIcon />}
                >
                  RSVP
                </Button>
                {/* <Button
                  disabled
                  variant="contained"
                  onClick={navigateTo("/schedule")}
                  style={{ marginTop: "20px", width: "150px" }}
                  startIcon={<ScheduleIcon />}
                >
                  Schedule
                </Button>
                <Button
                  disabled
                  variant="contained"
                  onClick={navigateTo("/photos")}
                  style={{ marginTop: "20px", width: "150px" }}
                  startIcon={<PhotoCameraIcon />}
                >
                  Photos
                </Button> */}
              </>
            ) : (
              <></>
            )}
            {!unlocked ? (
              <Button
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  unlocking();
                }}
                style={{ marginTop: "20px", width: "150px" }}
                startIcon={<LockIcon />}
              >
                Unlock
              </Button>
            ) : (
              <></>
            )}
            <>
              <Button
                // style={{ display: "none", width: "200px" }}
                style={{ marginTop: "20px", width: "150px" }}
                variant="contained"
                startIcon={<GetAppIcon />}
                onClick={handleInstallClick}
                disabled={!deferredPrompt}
              >
                Install App
              </Button>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
