import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";

export default function Login(props) {
  const { supabase, onSuccess, onFailed } = props;
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const passwordRef = React.useRef(null);

  const checkPassword = async (inputPassword) => {
    setLoading(true);
    const { data, error } = await supabase.login(username, inputPassword);
    setLoading(false);
    if (error) {
      console.log("Error", error);
      setPassword("");
      if (onFailed) {
        onFailed();
      }
      return;
    }
    if (data) {
      console.log("Success", data);
      console.log("props", props);
      console.log("onSuccess", onSuccess);
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {/* <form onSubmit={(e) => e.preventDefault()}> */}
      <Grid
        container
        spacing={1}
        //   center it all
        direction="column"
        justifyContent="center"
        alignItems="center"
        // white background
        // style={{ height: "100vh" }}
      >
        <Grid item size={12}>
          <TextField // Add a text field for the username
            label="Username"
            variant="outlined"
            value={username}
            autoFocus
            fullWidth
            disabled={loading}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => {
              // move to the next field when the user presses Enter
              if (e.key === "Enter") {
                passwordRef.current.focus();
              }
            }}
          />
        </Grid>
        <Grid item size={12}>
          <TextField // Add a text field for the password
            inputRef={passwordRef}
            label="Password"
            variant="outlined"
            type="password"
            value={password}
            fullWidth
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                checkPassword(password);
              }
            }}
          />
        </Grid>
        <Grid
          item
          size={12} // Add a button to submit the password
        >
          <LoadingButton
            fullWidth
            loading={loading}
            disabled={loading}
            variant="contained"
            onClick={() => checkPassword(password)}
            style={{ height: "100%" }}
          >
            <SendIcon />
          </LoadingButton>
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
}
