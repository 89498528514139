import React, { useEffect } from "react";
import Invitee from "../../components/Invitee";
import { use } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IconButton, Button, Icon } from "@mui/material";
import { Box, maxWidth } from "@mui/system";
import Grid from "@mui/material/Grid2";

import { TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import YesNo from "../../components/yesNo";
import LoadingButton from "@mui/lab/LoadingButton";
import PassCheck from "../../components/PassCheck";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Fab from "@mui/material/Fab";

export default function RsvpEdit(props) {
  console.log("RsvpEdit", props);
  const supabase = props.supabase;
  const [autoSave, setAutoSave] = useState(true);
  const navigate = useNavigate();
  const [modal, setModal] = useState(null);
  const [initialInvitees, setInitialInvitees] = useState([]);
  const [toBeSaved, setToBeSaved] = useState(false);
  const [invitees, setInvitees] = useState(null);
  const [tempPhone, setTempPhone] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [bookingNumber, setBookingNumber] = useState(null);

  // do something when leaving the page
  useEffect(() => {
    // get parameters from url
    const urlParams = new URLSearchParams(window.location.search);
    // Add event listener for beforeunload event
    const handleBeforeUnload = (event) => {
      saveInvitees(invitees);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    console.log("Supabase", supabase);
    if (!supabase) {
      return;
    }
    // get parameters from url
    const urlParams = new URLSearchParams(window.location.search);
    const bookingNumber = urlParams.get("bookingNumber");
    setBookingNumber(bookingNumber);
    const lastName = urlParams.get("lastName");
    console.log("Booking Number", bookingNumber);
    console.log("Last Name", lastName);
    checkRsvp(bookingNumber, lastName);
  }, []);

  const checkRsvp = async (bookingNumber, lastName) => {
    console.log("Checking RSVP");
    localStorage.setItem(
      "rsvp",
      JSON.stringify({
        bookingNumber: bookingNumber,
        // lastName: lastName,
      })
    );
    const { data, error } = await supabase.getGuestsByNameAndCode(
      bookingNumber,
      lastName
    );

    if (error) {
      console.error("Error getting guests", error);
      props.setModal(
        <PassCheck
          supabase={supabase}
          onSuccess={() => {
            props.setModal(null);
            checkRsvp(bookingNumber, lastName);
            props.verifyUSer();
          }}
          onFailed={() => {
            props.setToast("Incorrect password", "error");
          }}
        />
      );
      return;
    }
    console.log("Data", data);
    if (data.length === 0) {
      console.error("No guests found");
      navigate("/check-rsvp");
      return;
    }
    localStorage.setItem(
      "rsvp",
      JSON.stringify({
        bookingNumber: bookingNumber,
        lastName: lastName,
      })
    );
    setInitialInvitees(JSON.parse(JSON.stringify(data)));
    setInvitees(JSON.parse(JSON.stringify(data))); // Create a deep copy

    setTempPhone(data[0].phonenumber);
    setTempEmail(data[0].email);
    console.log(data);
  };

  useEffect(() => {
    if (!invitees) {
      return;
    }
    console.log("Invitees", invitees);
    console.log("Initial Invitees", initialInvitees);
    console.log(
      "tobesaved",
      JSON.stringify(initialInvitees) !== JSON.stringify(invitees)
    );
    console.log(initialInvitees[0].phonenumber);
    console.log(initialInvitees[0].email);
    setToBeSaved(
      JSON.stringify(initialInvitees) !== JSON.stringify(invitees) ||
        initialInvitees[0].phonenumber !== tempPhone ||
        initialInvitees[0].email !== tempEmail
    );
  }, [invitees, tempPhone, tempEmail, initialInvitees]);

  const handleInviteeChange = (index, e) => {
    console.log("handleInviteeChange", index, e.target);
    const { name, value } = e.target;
    console.log("handleInviteeChange - Name", name);
    console.log("handleInviteeChange - Value", value);
    var newInvitees = [...invitees];
    console.log("handleInviteeChange - newInvitees", newInvitees);
    newInvitees[index][name] = value;
    console.log("handleInviteeChange - newInvitees[index][name]", newInvitees);
    setInvitees(newInvitees);
    return newInvitees;
  };

  const deleteInvitee = (index) => {
    props.setModal(
      <YesNo
        title="Delete invitee"
        query={`Are you sure you want to delete ${invitees[index].firstname}?`}
        onYes={
          // reset then close modal
          () => {
            const newInvitees = [...invitees];
            newInvitees.splice(index, 1);
            setInvitees(newInvitees);
            setModal(null);
          }
        }
        onNo={() => setModal(null)}
      />
    );
  };

  const addInvitee = () => {
    setAutoSave(false);
    const newInvitees = [...invitees];
    newInvitees.push({
      id: null,
      firstname: "",
      lastname: "",
      email: "",
      phonenumber: "",
      attending: null,
      child: null,
    });
    setInvitees(newInvitees);
  };

  const saveInvitees = async (invitees, force = false) => {
    if (!autoSave && !force) {
      return;
    }
    console.log("Saving invitees", invitees);
    setLoading(true);
    // do a call for each invitee
    console.log("Invitees", invitees);
    invitees.forEach(async (invitee) => {
      invitee.phonenumber = tempPhone;
      invitee.email = tempEmail;
      console.log(tempEmail);
      const { data, error } = await supabase.updateGuests(
        invitee,
        bookingNumber
      );
      if (error) {
        console.error("Error updating guests", error);
        props.setToast("There was an issue", "error");

        setLoading(false);
        return;
      }
      console.log("Data", data);
    });
    props.setToast("Saved", "success");
    setInitialInvitees(invitees);
    // setInvitees(invitees);
    setToBeSaved(false);
    // checkRsvp(bookingNumber, invitees[0].lastname); // the returned data is not updated
    setLoading(false);
  };

  useEffect(() => {
    console.log("tempEmail", tempEmail);
    console.log("tempPhone", tempPhone);
  }, [tempEmail, tempPhone]);
  // if (!invitees) {
  //   return <CircularProgress />;
  // }

  return (
    <div // add blur if not unlockedor  no invitees
      style={{
        filter: invitees ? "none" : "blur(5px)",
        // bottomMargin: "50px",
        // bottom padding
        paddingBottom: "70px",
      }}
    >
      {/* <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={() => navigate(-1)}
        sx={{ position: "relative", top: "10px", left: "10px" }}
      >
        <ArrowBackIcon />
      </IconButton> */}
      <Box
        sx={{ flexGrow: 1 }}
        style={{
          maxWidth: "90%",
          margin: "auto",
        }}
      >
        <div>
          <h1>Edit your Invite</h1>
        </div>
        <Grid
          container
          spacing={1}
          padding={2}
          // center content
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item size={{ xs: 12, sm: 4 }}>
            <TextField
              label="Email"
              variant="outlined"
              margin="normal"
              type="text"
              fullWidth
              disabled={!invitees}
              value={tempEmail}
              onChange={(e) => {
                e.preventDefault();
                setTempEmail(e.target.value);
              }}
              onBlur={(e) => {
                saveInvitees(invitees);
              }}
            />
          </Grid>
          <Grid item size={{ xs: 12, sm: 4 }}>
            <TextField
              label="Phone"
              variant="outlined"
              margin="normal"
              type="text"
              fullWidth
              disabled={!invitees}
              value={tempPhone}
              onChange={(e) => setTempPhone(e.target.value)}
              onBlur={(e) => {
                saveInvitees(invitees);
              }}
            />
          </Grid>
        </Grid>
        {invitees?.length > 0 ? (
          <Grid
            container
            spacing={2}
            // center content
            padding={2}
          >
            {invitees.map((invitee, index) => {
              return (
                <Grid item size={{ xs: 12, sm: 4 }} key={index}>
                  <Invitee
                    saveInvitees={() => saveInvitees(invitees)}
                    key={index}
                    invitee={invitee}
                    index={index}
                    handleInviteeChange={handleInviteeChange}
                    deleteInvitee={deleteInvitee}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <h2>No invitees</h2>
        )}
        {/* add invitee button */}

        <Grid
          container // center content
          display="flex"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          direction={"column"}
        >
          <Grid item size={{ xs: 12, sm: 4 }}>
            <Button
              onClick={addInvitee}
              startIcon={<AddIcon />}
              fullWidth
              variant="outlined"
              style={{ marginBottom: "40px" }}
            >
              Add Invitee
            </Button>
          </Grid>
          <Grid item size={{ xs: 12, sm: 4 }}>
            <LoadingButton
              style={{ marginTop: "10px", display: "none" }}
              onClick={() => saveInvitees(invitees)}
              startIcon={<SaveIcon />}
              variant="contained"
              fullWidth
              // style={{ marginTop: "10px" }}
              loading={loading}
              disabled={!toBeSaved}
            >
              Save
            </LoadingButton>
          </Grid>
          {!autoSave && (
            <Grid item size={{ xs: 12, sm: 4 }}>
              <Fab
                style={{
                  position: "fixed",
                  bottom: "70px",
                  right: "10px",
                  transform: "translateX(-50%)",
                  transition: "bottom 0.3s",
                }}
                color="primary"
                onClick={() => saveInvitees(invitees, true)}
                variant="contained"
                fullWidth
                loading={loading}
              >
                <SaveIcon />
              </Fab>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}
