import React, { useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
//   set value based on location

const BottomNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [value, setValue] = React.useState(0);
  const [pages, setPages] = React.useState(props.pages);
  const [page, setPage] = React.useState(null);
  useEffect(() => {
    setPages(props.pages);
    // setPage(pages.find((page) => page.link === path));
  }, [props.pages]);

  useEffect(() => {
    // find page based on location
    const page = pages.find((page) => page.link === path);
    if (page) {
      console.log("Page", page);
      console.log("Page Index", pages.indexOf(page));
      console.log("Value", value);
      if (pages.indexOf(page) !== value) {
        setValue(pages.indexOf(page));
      }
    }
  }, [page, pages, value, location]);

  const navigateTo = (path) => {
    navigate(path);
  };
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backdropFilter: "blur(10px)",
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          if (newValue === value) {
            return;
          }
          setValue(newValue);
        }}
      >
        {pages.map((page, index) => (
          <BottomNavigationAction
            key={index}
            label={page.title}
            icon={page.icon}
            onClick={() => {
              // console.log("Navigating to", page.link);
              navigateTo(page.link);
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
