import React, { useEffect, useState } from "react";
import { CardMedia, CircularProgress } from "@mui/material";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
const PhotoCardUpload = (props) => {
  const photo = props?.photo;
  return (
    <div style={{ position: "relative" }}>
      <IconButton
        color="primary"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "white",
        }}
        onClick={() => {
          props.deletePhoto(photo.id);
          console.log("Delete the photo");
        }}
      >
        <DeleteIcon />
      </IconButton>
      <img
        component="img"
        alt={photo.title}
        src={photo.url}
        image={photo.url}
        style={{
          width: "100%",
          objectFit: "cover",
          aspectRatio: "1 / 1", // Ensure the image is square
        }}
      />
    </div>
  );
};

export default PhotoCardUpload;
