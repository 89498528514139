import React, { useEffect, useState } from "react";

const PhotoCardMedia = ({
  photo,
  imageLoading,
  handleImageLoad,
  handleImageLoadStart,
}) => {
  return (
    <>
      <img
        component="img"
        alt={photo.title}
        src={photo.url}
        image={photo.url}
        style={{
          width: "100%",
          objectFit: "cover",

          display: imageLoading[photo.id] ? "none" : "block",
          aspectRatio: "1 / 1", // Ensure the image is square
        }}
        onLoad={() => handleImageLoad(photo.id)}
        onLoadStart={() => handleImageLoadStart(photo.id)}
      />
    </>
  );
};

export default PhotoCardMedia;
