import React, { useState } from "react";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
export default function YesNo(props) {
  const [query, setQuery] = useState(props.query);
  const [answer, setAnswer] = useState(null);

  const handleYesClick = () => {
    console.log("Yes clicked");
    if (props.onYes) {
      props.onYes();
    }
    setAnswer(true);
  };

  const handleNoClick = () => {
    console.log("No clicked");
    if (props.onNo) {
      props.onNo();
    }
    setAnswer(false);
  };

  return (
    <>
      <h3>{query}</h3>
      <br />
      <Grid container spacing={2}>
        <Grid item size={6}>
          <Button
            fullWidth
            onClick={handleNoClick}
            variant="contained"
            color="secondary"
          >
            No
          </Button>
        </Grid>
        <Grid item size={6}>
          <Button fullWidth onClick={handleYesClick} variant="contained">
            Yes
          </Button>
        </Grid>
      </Grid>
      {/* {answer !== null && <p>Selected answer: {answer.toString()}</p>} */}
    </>
  );
}
