import React from "react";

import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";

export default function Countdown({ toDate }) {
  // transform the date to a timestamp

  // const date = new Date().getTime() + 1000 * 60 * 60 * 24;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%",
        overflow: "hidden",
        marginTop: "50px",
      }}
    >
      <FlipClockCountdown
        to={toDate}
        digitBlockStyle={{
          // width: "100%",
          maxWidth: window.innerWidth < 1000 ? 25 : 40,
          height: 60,
          fontSize: 30,
        }}
        labelStyle={{
          color: "white",
          // add outline
          textShadow: "0 0 10px black",
        }}
      />
    </div>
  );
}
