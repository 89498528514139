import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Container,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Typography,
  Fab,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import TakePhoto from "./TakePhoto";
import { useNavigate } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PhotosGrid from "./PhotosGrid";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Utils from "../../utils/utils";
const initialPhotos = [
  // { id: 1, title: "Photo 1", url: "https://picsum.photos/id/1/150" },
];

const Photos = (props) => {
  const navigate = useNavigate();
  const supabase = props.supabase;
  const [photos, setPhotos] = useState(initialPhotos);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [photosList, setPhotosList] = useState(null);
  const [imageLoading, setImageLoading] = useState({});
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const utils = new Utils();

  const observer = useRef();

  useEffect(() => {
    // list all files in the public folder
    // getPhotosNames();
    listPhotos();
  }, []);

  const listPhotos = async () => {
    const { data, error } = await supabase.listFiles("pics", "public");
    if (error) {
      console.log("Error listing photos", error);
      props.setToast("Error listing photos", "error");
    }
    console.log(data, error);
    if (data) {
      console.log("Data from listFiles:", data);
      setPhotosList(data);
    }
  };

  useEffect(() => {
    if (!photosList) return;
    console.log("photosList", photosList);
    loadMorePhotos();
  }, [photosList]);

  const handleNavigate = () => {
    navigate("/photos/choose");
  };
  const loadMorePhotos = async () => {
    if (!photosList) return;
    if (photosList.length === photos.length) return;
    setLoading(true);
    // get files names from size of photos to size of photos + 10
    console.log("image to get", photosList[0].name);
    // const { data, error } = await supabase.getFiles(photosList[0].name);
    // console.log("image", data);
    // const downloadUrl = URL.createObjectURL(data);
    // Now you can use 'downloadUrl' as needed in your code
    // console.log("Download URL:", downloadUrl);
    // setImage(downloadUrl);

    console.log("photosList", photosList);
    console.log("photosList.length", photosList.length);

    const filesToGet = photosList.slice(photos.length, photos.length + 10);
    console.log("filesToGet", filesToGet);
    if (filesToGet.length === 0) return;
    const filesName = filesToGet.map((file) => file.name);
    const { data, error } = await supabase.getFiles(filesName);
    const newPhotos = data.map((blob, index) => {
      const downloadUrl = URL.createObjectURL(blob);
      return {
        id: photos.length + index,
        title: downloadUrl,
        url: downloadUrl,
      };
    });
    // get them from supabase in 1 call

    setPhotos((prevPhotos) => [...prevPhotos, ...newPhotos]);
    setPage((prevPage) => prevPage + 1);
    setLoading(false);
  };
  const handleImageLoad = (id) => {
    setImageLoading((prev) => ({ ...prev, [id]: false }));
  };

  const handleImageLoadStart = (id) => {
    setImageLoading((prev) => ({ ...prev, [id]: true }));
  };
  const loadMorePhotosCallBack = useCallback(() => {
    loadMorePhotos();
  }, [photos]);

  const lastPhotoElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMorePhotosCallBack();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadMorePhotosCallBack]
  );

  const memorizedPhotos = useMemo(() => photos, [photos]);

  const handleFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;
        console.log("Base64 data:", base64data);
        // Upload the base64data to your server or storage service
        // Example: Upload to Supabase storage
        // base64data is a string, so we need to convert it to a blob
        const blob = await fetch(base64data).then((res) => res.blob());
        console.log("Blob data:", blob);
        const uuid = utils.generateUUID() + ".png";
        console.log("UUID:", uuid);
        const { data, error } = await supabase.uploadPublicPics(uuid, blob);

        if (error) {
          console.error("Error uploading photo:", error);
          props.setToast("Error uploading photo", "error");
        } else {
          console.log("Photo uploaded successfully:", data);
          const downloadUrl = URL.createObjectURL(blob);

          // Update the photos list with the new photo
          setPhotos((prevPhotos) => [
            {
              id: photos.length + downloadUrl,
              title: downloadUrl,
              url: downloadUrl,
            },
            ...prevPhotos,
          ]);
        }
      };
      reader.readAsDataURL(file);
    }
    setLoading(false);
  };

  return (
    <div
      // minimize the padding on the side
      sx={{ padding: 0 }}
    >
      <TakePhoto />

      <PhotosGrid
        photos={memorizedPhotos}
        imageLoading={imageLoading}
        handleImageLoad={handleImageLoad}
        handleImageLoadStart={handleImageLoadStart}
        lastPhotoElementRef={lastPhotoElementRef}
        setModal={props.setModal}
        loading={loading}
        handleNavigate={handleNavigate}
      />
      <input
        type="file"
        accept="image/*,video/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {/* <Fab
        // create an animation to make it appear
        // make it square

        color="secondary"
        aria-label="add"
        onClick={() => fileInputRef.current.click()}
        style={{
          position: "fixed",
          bottom: "130px",
          right: "25px",
          animation: "popIn 0.3s ease-in-out",
          width: "40px",
          height: "40px",
          minHeight: "40px",
          // borderRadius: "0", // Make it square
          variant: "extended",
        }}
      >
        <PhotoLibraryIcon />
      </Fab> */}
      <Fab
        color="primary"
        aria-label="add"
        onClick={handleNavigate}
        style={{
          position: "fixed",
          bottom: "70px",
          right: "20px",
          animation: "popIn 0.2s ease-in-out",
          width: "50px",
          height: "50px",
          minHeight: "50px",
        }}
      >
        <AddAPhotoIcon />
      </Fab>
    </div>
  );
};

export default Photos;
