import React from "react";
import { Container, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import "../../font.css";
const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      fontSize: "2.5rem",
      fontWeight: "bold",
    },
  },
});

const Story = () => {
  return (
    <Container style={{ position: "relative", paddingTop: "20px" }}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src="/img/story.png"
          alt="flower"
          style={{
            maxWidth: "100vw",
            width: "auto",
            height: "auto",
            maxHeight: "100vh",
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(90deg)",
            zIndex: -1,
          }}
        />
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          paddingBottom={2}
          style={{ fontFamily: "Adelia, sans-serif" }}
        >
          Our Story
        </Typography>
        <Typography>
          Once upon a time, in a land not so far a way we met was through mutual
          friends of ours. At that moment I believe we were both not looking to
          date. Larissa was in her 'I don't need a man' era and was definitely
          not looking for a boyfriend. But That night as the night went on, it
          felt like we had known each other for a while even thought we we had
          only known each other for a few hours. <br />
          The night went on and we danced and I drank a little be too much maybe
          and had to go home early ;).
          <br />
          The next day as when I woke up, I remembered that we did not exchange
          numbers so I thought I would never see him again and I was like, what
          a bummer! Resourceful as he is, he managed to get a hold of my number
          and the rest is history ;).
        </Typography>
      </Paper>
    </Container>
  );
};

export default Story;
