// create supabase class

import { createClient } from "@supabase/supabase-js";
import { jwtDecode } from "jwt-decode";

export default class SupabaseClient {
  constructor(data) {
    this.initSupabase();
  }

  async initSupabase() {
    const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
    const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

    this.supabase = await createClient(supabaseUrl, supabaseKey);
    return this.supabase;
  }

  getDataFromTable = async (table) => {
    const { data, error } = await this.supabase.from(table).select();
    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };

  getGuests = async () => {
    // return this.getDataFromTable("guests");
    const { data, error } = await this.supabase.rpc("select_all_guests");
    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };

  getGuestsByNameAndCode = async (code, name) => {
    console.log("Getting guests by name and code", name, code);
    if (this.supabase === undefined) {
      console.error("Supabase not initialized");
      return { data: [], error: "Supabase not initialized" };
    }

    if (!code || !name) {
      return { data: [], error: "Missing code or name" };
    }
    const { data, error } = await this.supabase.rpc("get_people_by_code", {
      given_code: code,
      last_name: name,
    });

    console.log("Data from table:", data);
    console.log("Error from table:", error);
    // get status code
    return { data, error };
  };

  login = async (email, password) => {
    const { data, error } = await this.supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    console.log(data, error);
    return { data, error };
  };

  logout = async () => {
    const { error } = await this.supabase.auth.signOut();
    console.log("Error", error);
    return { error };
  };

  getUser = async () => {
    try {
      const { data, error } = await this.supabase.auth.getUser();
      console.log("User", data, error);
      const token = await this.supabase.auth.getSession();
      console.log("Token", token);
      console.log("expires_in", token.data.session.expires_in);
      console.log("decode token", jwtDecode(token.data.session.access_token));
      const decodedToken = jwtDecode(token.data.session.access_token);
      console.log("decodedToken", decodedToken);
      console.log("Keys in decodedToken:", Object.keys(decodedToken));
      console.log("exp", decodedToken.exp);
      // current epoch time
      const now = Math.floor(Date.now() / 1000);
      console.log("now", now);
      console.log("diff", decodedToken.exp - now);
      if (decodedToken.exp - now < 0) {
        console.log("Token has expired");
        //  refresh token
        // return expired token
        return { expired: decodedToken.exp - now < 0 };
      }

      // if (isExpired) {
      //   console.log("JWT has expired");
      // }
      console.log("User", data.user, error);
      // get response code
      console.log("data.status", data);

      if (data.user) {
        return { data: data.user };
      } else {
        return { error: "error" };
      }
    } catch (error) {
      return { error: error.message };
    }
  };

  updateGuests = async (guest, rsvp_code) => {
    console.log("Updating guest", guest);
    // const { data, error } = await this.supabase.from("guests").upsert(guest);
    const { data, error } = await this.supabase.rpc("update_guest_info", {
      _id: guest.id,
      _rsvp_code: rsvp_code,
      _email: guest.email,
      _attending: guest.attending,
      _phonenumber: guest.phonenumber,
      _firstname: guest.firstname,
      _lastname: guest.lastname,
      _child: guest.child,
    });
    // const { data, error } = await this.supabase.from("guests").upsert(guest);
    console.log("Data from table:", data);
    console.log("Error from table:", error);
    return { data, error };
  };

  uploadPublicPics = async (file, blob) => {
    // const folder = "21quh_0";
    try {
      const folder = "public";
      const filepath = `${folder}/${file}`;
      const { data, error } = await this.supabase.storage
        .from("pics")
        .upload(filepath, blob, {
          cacheControl: "3600",
          upsert: false,
        });
      console.log("data", data);
      console.log("error", error);
      if (error) {
        // Handle error
        return { error: error };
      } else {
        // Handle success
        return { data: data };
      }
    } catch (error) {
      console.log("Error uploading photo:", error);
      return { error: error };
    }
  };

  uploadPrivatePics = async (file, blob) => {
    // const folder = "21quh_0";
    const folder = "private";
    const filepath = `${folder}/${file}`;
    const { data, error } = await this.supabase.storage
      .from("pics")
      .upload(filepath, blob, {
        cacheControl: "3600",
        upsert: false,
      });
    if (error) {
      // Handle error
      return { error: error };
    } else {
      // Handle success
      return { data: data };
    }
  };

  getPrivateFilenames = async () => {
    const { data, error } = await this.supabase.storage
      .from("pics")
      .list("private", { orderBy: { column: "created_at", ascending: false } });
    if (error) {
      // Handle error
      return { error: error };
    } else {
      // Handle success
      // make sure to filter out the folders
      const filteredData = data.filter(
        (item) => item.name.includes(".") && !item.name.startsWith(".")
      );
      return { data: filteredData };
    }
  };

  // get mulitple files URL from public folder
  // getPublicPics = async (files) => {
  //   const signedUrls = [];
  //   const folder = "private";
  //   for (const file of files) {
  //     const filepath = `${folder}/${file}`;
  //     const { data, error } = await this.supabase.storage
  //       .from("pics")
  //       .createSignedUrl(filepath, 60, {
  //         transform: {
  //           width: 200,
  //           height: 200,
  //         },
  //       });
  //     if (error) {
  //       // Handle error
  //       console.log("Error getting signed URL", error);
  //       return { error: error };
  //     } else {
  //       signedUrls.push(data.signedUrl);
  //     }
  //   }
  //   console.log("Signed URLs", signedUrls);
  //   return { data: signedUrls };
  // };

  getPublicFilenames = async () => {
    const { data, error } = await this.supabase.storage
      .from("pics")
      .list("public", { orderBy: { column: "created_at", ascending: false } });
    console.log("data.status", data.status);

    if (error) {
      // Handle error
      return { error: error };
    } else {
      // Handle success
      // make sure to filter out the folders
      const filteredData = data.filter(
        (item) => item.name.includes(".") && !item.name.startsWith(".")
      );
      return { data: filteredData };
    }
  };

  listFiles = async (bucketName, folderPath) => {
    const { data, error } = await this.supabase.storage
      .from(bucketName)
      .list(folderPath, {
        // order by created_at
        sortBy: { column: "created_at", order: "desc" },
        // Optional: Set this to true if you want to include the full path in the results
        // This is useful if you want to see the folder structure
        // This option is not available in all versions, check the documentation
        // include: 'full_path'
      });

    if (error) {
      console.error("Error listing files:", error);
      return;
    }
    const filteredData = data.filter(
      (file) => file.name.includes(".") && !file.name.startsWith(".")
    );
    // Print each file name
    filteredData.forEach((file) => {
      console.log(file.name);
    });
    return { data: filteredData };
  };

  getFiles = async (files) => {
    const bucketName = "pics";
    const folderPath = "public";
    const blobs = [];
    for (const file of files) {
      const filepath = `${folderPath}/${file}`;
      const { data, error } = await this.supabase.storage
        .from(bucketName)
        .download(filepath, {
          // Optional: Set this to true if you want to include the full path in the results
          // This is useful if you want to see the folder structure
          // This option is not available in all versions, check the documentation
          // include: 'full_path'
        });

      if (error) {
        console.error("Error listing image:", error);
        return;
      }
      blobs.push(data);
    }

    return { data: blobs };
  };

  // get mulitple files URL from public folder
  getPublicPics = async (files) => {
    const signedUrls = [];
    const folder = "public";
    for (const file of files) {
      const filepath = `${folder}/${file}`;
      const { data, error } = await this.supabase.storage
        .from("pics")
        .createSignedUrl(filepath, 60, {
          transform: {
            width: 200,
            height: 200,
          },
        });
      if (error) {
        // Handle error
        console.log("Error getting signed URL", error);
        return { error: error };
      } else {
        signedUrls.push(data.signedUrl);
      }
    }
    console.log("Signed URLs", signedUrls);
    return { data: signedUrls };
  };
}
