import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "../../font.css";
const schedule = [
  {
    time: "09:00",
    event: "Arrival",
    description: "some extra info are found here",
    attendees: ["John", "Jane", "Doe"],
  },
  {
    time: "11:00",
    event: "Ceremony",
    description: "some extra info are found here",
  },
  {
    time: "13:00",
    event: "Lunch Break",
    description: "some extra info are found here",
  },
  {
    time: "14:00",
    event: "Project Discussion",
    description: "some extra info are found here",
  },
  {
    time: "17:00",
    event: "Wrap Up",
    description: "some extra info are found here",
  },
];
const rsvp = JSON.parse(localStorage.getItem("rsvp"));

const lastName = rsvp ? rsvp.lastName : null;

const SchedulePage = () => {
  const [enabled, setEnabled] = React.useState(false);
  const [url, setUrl] = React.useState(null);
  const navigate = useNavigate();
  const navigateTo = (url) => {
    navigate(url);
  };
  return (
    <Container style={{ position: "relative", paddingTop: "20px" }}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src="/img/border.png"
          alt="border"
          style={{
            width: "auto",
            height: "auto",
            maxHeight: "100%",
            maxWidth: "70%",
            borderRadius: "10px",
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: -1,
          }}
        />
        <img
          src="/img/border.png"
          alt="border"
          style={{
            width: "auto",
            height: "auto",
            maxHeight: "100%",
            maxWidth: "70%",
            borderRadius: "10px",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: -1,
            transform: "rotate(180deg)",
          }}
        />
        <img
          src="/img/centerFlower.png"
          alt="flower"
          style={{
            width: "50%",
            height: "auto",
            maxHeight: "100%",
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: -1,
          }}
        />
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          paddingBottom={2}
          style={{ fontFamily: "Adelia, sans-serif" }}
        >
          Schedule
        </Typography>
        {schedule.map((item, index) => (
          <React.Fragment key={index}>
            <Divider>
              {item.time} {item.event}
            </Divider>
            <p>{item.description}</p>
          </React.Fragment>
        ))}
      </Paper>
    </Container>
  );
};

export default SchedulePage;
