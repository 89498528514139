import React, { useEffect, useState } from "react";
import { Camera } from "react-camera-pro";
import { Button } from "@mui/material";
import { Modal, Box, IconButton } from "@mui/material";
import CameraswitchOutlinedIcon from "@mui/icons-material/CameraswitchOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import Cropper from "react-easy-crop";

import YesNo from "../../components/yesNo";
export default function PhotoApp(props) {
  const camera = React.useRef(null);
  const [photo, setPhoto] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [videoConstraints, setVideoConstraints] = useState({
    width: 1280,
    height: 720,
    facingMode: "user",
  });

  useEffect(() => {
    // Get all the available lenses
    const getVideoInputs = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log("devices", devices);
    };
    getVideoInputs();

    // Cleanup function to stop the camera when leaving the screen
    return () => {
      if (camera.current) {
        const stream = camera.current.getStream();
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
        }
      }
    };
  }, []);

  const handleTakePhoto = () => {
    console.log("Take photo", camera.current);
    if (!camera.current) {
      return;
    }
    const photo = camera.current.takePhoto();
    setPhoto(photo);
  };

  const changeFacingMode = () => {
    if (!camera.current) {
      return;
    }
    try {
      camera.current.switchCamera();
    } catch (e) {
      console.log("Error", e);
    }
    console.log("Change facing mode", videoConstraints.facingMode);
    setVideoConstraints({
      ...videoConstraints,
      facingMode:
        videoConstraints.facingMode === "user" ? "environment" : "user",
    });
  };

  const deletePhoto = () => {
    props.setModal(
      <YesNo
        query={"Are you sure you want to cancel?"}
        onYes={() => {
          setPhoto(null);
          props.setModal(null);
        }}
        onNo={() => props.setModal(null)}
      />
    );
  };

  const handleZoomChange = (zoom) => {
    console.log("Zoom", zoom);
    setZoom(zoom);
  };

  return (
    <div>
      {photo ? (
        <>
          <IconButton
            onClick={() => {
              deletePhoto();
            }}
            style={{
              position: "absolute",
              backgroundColor: "white",
              // top: "50%",
              right: "50px",
              // transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
          <img
            src={photo}
            alt=" "
            width="100%"
            maxWidth="100%"
            maxHeight="100%"
            height="auto"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%) ${
                videoConstraints.facingMode === "user" ? "" : "scaleX(-1)"
              }`,
            }}
          />
        </>
      ) : (
        <>
          <IconButton
            onClick={changeFacingMode}
            variant="contained"
            style={{
              position: "fixed",
              top: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "white",
              zIndex: 1000,
            }}
          >
            <CameraswitchOutlinedIcon />
          </IconButton>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              transform: `${
                videoConstraints.facingMode === "user" ? "scaleX(-1)" : ""
              }`, // Mirror the image horizontally
            }}
          >
            <Cropper
              image={null} // No image to crop, just using for pinch-to-zoom
              crop={crop}
              zoom={zoom}
              aspect={16 / 9}
              onCropChange={setCrop}
              onZoomChange={handleZoomChange}
              minZoom={1}
              maxZoom={3}
              style={{
                containerStyle: {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 10,
                },
              }}
            />
            <Camera
              audio={false}
              ref={camera}
              screenshotFormat="image/jpeg"
              facingMode="user"
              zoom={zoom}
              width="100%"
              maxWidth="100%"
              maxHeight="100%"
              height="auto"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>

          <IconButton
            onClick={handleTakePhoto}
            variant="contained"
            style={{
              position: "fixed",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
            }}
          >
            <RadioButtonCheckedOutlinedIcon
              // make it extra big even bigger
              style={{ fontSize: "50px" }}
            />
          </IconButton>
        </>
      )}
    </div>
  );
}
