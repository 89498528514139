import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import YesNo from "../../components/yesNo";
import RefreshIcon from "@mui/icons-material/Refresh";
import Utils from "../../utils/utils";
import { Backdrop, CircularProgress } from "@mui/material";
const PhotoEdit = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const supabase = props.supabase;
  const utils = new Utils();
  const [photo, setPhoto] = useState(props?.photo); // Destructure the photo from the state
  const fileInputRef = useRef(null);
  const [fileInputFocused, setFileInputFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Photo", photo);
    console.log("Location state", location.state);
    // get the photo from the navigate state
    if (location.state?.photo) {
      setPhoto(location.state.photo);
    } else if (props?.photo) {
      setPhoto(props.photo);
    } else if (!photo) {
      fileInputRef.current.click();
    } else {
      setPhoto(photo);
    }
  }, [location.state, navigate, photo, props]);

  const handleFileChange = (event) => {
    console.log("File change");
    const file = event.target.files[0];
    console.log("File", event.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleFileInputFocus = () => {
    console.log("File input focus");
    setFileInputFocused(true);
  };

  const handleClose = () => {
    props.setModal(
      <YesNo
        query={"Are you sure you want to cancel?"}
        onYes={() => {
          navigate(-1);
          props.setModal(null);
        }}
        onNo={() => props.setModal(null)}
      />
    );
  };

  const handleFileInputBlur = () => {
    console.log("File input blur");
    if (fileInputFocused && !fileInputRef.current.files.length) {
      // User hit cancel, navigate back or handle accordingly
      // navigate(-1);
    }
    setFileInputFocused(false);
  };

  const base64ToBlob = (base64, mimeType) => {
    console.log("base64", base64);

    const byteString = atob(base64.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeType });
  };

  const handlePublicUpload = async () => {
    const blob = base64ToBlob(photo.base64data, "image/png");
    setLoading(true);
    const uuid = utils.generateUUID();
    const filename = `${uuid}.png`;
    const { data, error } = await supabase.uploadPublicPics(filename, blob);
    if (error) {
      if (error.message === "Request failed with status code 413") {
        props.setToast("File size is too large", "error");
      }

      // Handle error
      console.log("Error uploading photo:", error);
    } else {
      // Handle success
      console.log("Photo uploaded successfully:", data);
      navigate("/photos");
    }
    setLoading(false);
  };

  const handlePrivateUpload = async () => {
    console.log("Private upload");
    const blob = base64ToBlob(photo, "image/png");
    setLoading(true);
    const uuid = utils.generateUUID();
    const filename = `${uuid}.png`;
    const { data, error } = await supabase.uploadPrivatePics(filename, blob);
    if (error) {
      // Handle error
      console.log("Error uploading photo:", error);
      props.setToast("Error uploading photo", "error");
    } else {
      // Handle success
      console.log("Photo uploaded successfully:", data);
      navigate("/photos");
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100%",
        margin: "auto",
      }}
    >
      {/* add overlay when is loading with transparency and a circuleloading */}
      <Container
        //make sure the container is full screen and content is centered vertically and horizontally
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          padding: 0,
        }}
      >
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleFileChange}
          onFocus={handleFileInputFocus}
          onBlur={handleFileInputBlur}
          style={{ display: "none" }}
          ref={fileInputRef}
          zIndex={-1}
        />
        {photo ? (
          <Card>
            <CardMedia component="img" image={photo.url} alt="Photo" />
          </Card>
        ) : (
          <h1>no photo</h1>
        )}
        <IconButton
          onClick={() => handleClose()}
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div
          //   position the buttons at the bottom
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            //   backgroundColor: "white",
          }}
        >
          <Grid container spacing={0.5}>
            <Grid item size={6}>
              <Button
                style={{ backgroundColor: "white" }}
                startIcon={<SendIcon />}
                onClick={handlePublicUpload}
                fullWidth
                disabled={!photo}
              >
                Share
              </Button>
            </Grid>
            <Grid item size={6}>
              <Button
                style={{ backgroundColor: "white" }}
                disabled={!photo}
                startIcon={<SendIcon />}
                fullWidth
                onClick={handlePrivateUpload}
              >
                Share privately
              </Button>
            </Grid>
            <Grid item size={12}>
              <label htmlFor="fileInput">
                <Button
                  variant="contained"
                  component="span"
                  onClick={() => fileInputRef.current.click()}
                  startIcon={photo ? <DeleteIcon /> : <RefreshIcon />}
                  fullWidth
                >
                  retry
                </Button>
              </label>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Backdrop sx={{ color: "#fff", zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default PhotoEdit;
