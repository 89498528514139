import React, { useEffect } from "react";
import { Container, Typography, Paper, Icon } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import "../../font.css";
const Info = () => {
  const [enabled, setEnabled] = React.useState(false);
  const [url, setUrl] = React.useState(null);
  const [isPWA, setIsPWA] = React.useState(false);
  const navigate = useNavigate();
  const navigateTo = (url) => {
    navigate(url);
  };

  useEffect(() => {
    addBeforeInstallPrompt();
  }, []);

  const addBeforeInstallPrompt = (e) => {
    let deferredPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      console.log("The app can be installed as a PWA.");

      // Optionally, show a custom install button
      const installButton = document.getElementById("install-button");
      installButton.style.display = "block";

      installButton.addEventListener("click", () => {
        // Show the install prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
          deferredPrompt = null;
        });
      });
    });
  };

  return (
    <Container style={{ position: "relative", paddingTop: "20px" }}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* add button on top right corner */}
        <Button
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,

            // do not change the cursor on hover
            cursor: "default",
          }}
          onClick={() => {
            console.log("Edit");
            // set Enabled for 2 seconds
            setEnabled(true);
            setUrl("/foeo");
            setTimeout(() => {
              setEnabled(false);
              setUrl(null);
            }, 2000);
          }}
        >
          Edit
        </Button>
        <Button
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            opacity: 0,
            enabled: enabled,
            cursor: "default",
          }}
          onClick={() => {
            if (enabled) {
              navigateTo(url);
            }
          }}
        >
          Edit
        </Button>
        <img
          src="/img/flower.png"
          alt="flower"
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "100%",
            objectFit: "cover",
            borderRadius: "10px",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: -1,
          }}
        />

        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          paddingBottom={2}
          style={{ fontFamily: "Adelia, sans-serif" }}
        >
          Info
        </Typography>
        <Typography variant="body1" component="p" style={{ textAlign: "left" }}>
          <span role="img" aria-label="date">
            📅
          </span>{" "}
          20th of July 2025
        </Typography>
        <Typography variant="body1" component="p" style={{ textAlign: "left" }}>
          <span role="img" aria-label="location">
            📍
          </span>{" "}
        </Typography>
        <Typography variant="body1" component="p" style={{ textAlign: "left" }}>
          <span role="img" aria-label="phone">
            📞
          </span>{" "}
        </Typography>
        <Typography variant="body1" component="p" style={{ textAlign: "left" }}>
          <span role="img" aria-label="email">
            📧
          </span>{" "}
        </Typography>
        <Typography variant="body1" component="p" style={{ textAlign: "left" }}>
          {/* add bank account
           */}
          <span role="img" aria-label="bank">
            💳
          </span>{" "}
        </Typography>
      </Paper>
    </Container>
  );
};

export default Info;
