import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Card, CardContent, CardActions, Typography } from "@mui/material";
import "./FoeoTable.css";
import { use } from "react";

const FoeoTable = (props) => {
  const data = props.data;
  const loading = props.loading;

  const navigate = useNavigate();
  // get full url
  const baseUrl = window.location.origin;

  const columns = [
    { field: "firstname", headerName: "First Name", width: 150 },
    { field: "lastname", headerName: "Last Name", width: 150 },
    { field: "rsvp_code", headerName: "RSVP Code", width: 150 },
    {
      field: "attending",
      headerName: "Link",
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            onClick={() => {
              navigateTo(
                `/edit-rsvp/?bookingNumber=${params.row.rsvp_code}&lastName=${params.row.lastname}`
              );
            }}
          >
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() =>
              copyToClipboard(
                `${baseUrl}/edit-rsvp/?bookingNumber=${params.row.rsvp_code}&lastName=${params.row.lastname}`
              )
            }
          >
            <ContentCopyIcon />
          </Button>
        </>
      ),
    },
  ];

  const navigateTo = (url) => {
    navigate(url);
  };
  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };
  return (
    <>
      <DataGrid
        // key={data.rsvp_code}

        rows={data}
        columns={columns}
        pageSize={-1}
        initialState={{
          pagination: {
            paginationModel: { pageSize: -1, page: 0 },
          },
        }}
        getRowClassName={(params) =>
          params.row.attending
            ? "super-app-theme--attending"
            : params.row.attending === false
            ? "super-app-theme--not-attending"
            : "super-app-theme--no-response"
        }
        loading={loading}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "linear-progress",
          },
        }}
        onRowClick={
          (row) => {
            console.log("Row clicked", row);
            copyToClipboard(
              `${baseUrl}/edit-rsvp/?bookingNumber=${row.row.rsvp_code}&lastName=${row.row.lastname}`
            );
          }
          // navigateTo(
          //   `/edit-rsvp/?bookingNumber=${row.row.rsvp_code}&lastName=${row.row.lastname}`
          // )
        }
      />
      {/* <div style={{ height: 400, width: "100%" }}>
      {data.map((invitee) => (
        <Card
          key={invitee.rsvp_code}
          sx={{
            margin: "5px",
            height: 50,
            // make it green if attending
            backgroundColor: invitee.attending
              ? "#d3f7d3"
              : invitee.attending === false
              ? "#f7d3d3"
              : "#f7f7f7",
          }}
        >
          <Grid
            container
            spacing={1}
            // set direction to row
            direction="row"
          >
            <Grid item xs={10}>
              <CardContent sx={{ height: 20 }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{
                    textAlign: "left",
                    marginTop: "-15px",
                    // whiteSpace: "nowrap",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  {invitee.firstname} {invitee.lastname}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  style={{
                    textAlign: "left",
                    marginTop: "-12px",
                  }}
                >
                  {invitee.rsvp_code}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={2}>
              <CardActions
                sx={{
                  display: "flex",
                  position: "relative",
                  right: 0,
                  // bottom: 0,
                }}
              >
                <Button
                  size="small"
                  onClick={() =>
                    copyToClipboard(
                      `${baseUrl}/edit-rsvp/?bookingNumber=${invitee.rsvp_code}&lastName=${invitee.lastname}`
                    )
                  }
                >
                  <ContentCopyIcon />
                </Button>
                <Button
                  // style={{ marginRight: "-15px" }}
                  size="small"
                  onClick={() => {
                    navigateTo(
                      `/edit-rsvp/?bookingNumber=${invitee.rsvp_code}&lastName=${invitee.lastname}`
                    );
                  }}
                >
                  <ArrowForwardIcon />
                </Button>
              </CardActions>
            </Grid>
          </Grid>
        </Card>

      ))}
    </div> */}
    </>
  );
};

export default FoeoTable;
